import React from "react";
import ReactHtmlParser from 'react-html-parser';

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

import CheckIcon from "../../../assets/medias/svg/icons/icon-check.svg";

const ChecksPageSectionComponent = ({ content }) => {
    const SectionContentParser = () => {
        let sectionCheckArray = [];

        content.checksContent.forEach((check, index) =>  {
            sectionCheckArray.push(
                <div key={index} className="d-flex mb-4">
                    <div className={"me-3 svg-" + content.theme}><CheckIcon /></div>
                    
                    <div>
                        <h3 className="fw-light fs-4-integral ls-3 mb-0">{check.subtitle}</h3>
                        <div className="fw-light fs-2-integral">{ ReactHtmlParser(check.text.html) }</div>
                    </div>
                </div>
            );
        });

        return(
            <>{sectionCheckArray}</>
        );
    };

    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container px-5">
                <div className="row d-flex align-items-center gx-4">
                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                        <em>{content.subtitle}</em>
                        <h2 className="display-3 fs-5-integral ls-4 mb-4">{content.title}</h2>

                        <SectionContentParser />
                    </div>

                    <div className="d-flex col-12 col-lg-6 justify-content-center">
                        <ImageBlockComponent src={content.image} alt={content.alt} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChecksPageSectionComponent;
