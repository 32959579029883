import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

const ArticleOverviewPageSectionComponent = ({ article, theme }) => {
    const {t} = useTranslation();

    return (
        <section className={"pb-5 section-" + theme + "-theme article-overview-wrapper"}>
            <div className="container px-4 mw-960px">
                <div className="row d-flex align-items-center">
                    <div className="d-flex col-12 col-md-4 justify-content-center mb-4 mb-md-0">
                        <ImageBlockComponent src={article.cover} alt={article.title} />
                    </div>

                    <div className="col-12 col-md-8">
                        <span className="fw-light fs-5 ls-3 mb-1">{article.date}</span>
                        <h2 className="fs-2 ls-4 mb-1">{article.title}</h2>

                        <div className="fw-light fs-6 mb-2">{article.metaDescription}</div>

                        <Link className="fw-bold btn btn-primary text-white" to={article.slug}>{t("app.button.readMore")}</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ArticleOverviewPageSectionComponent;