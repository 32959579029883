import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "gatsby-plugin-react-i18next";

const RedirectPageSectionComponent = ({ content }) => {
    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container d-flex flex-column align-items-center px-5 px-md-3 px-lg-5 text-center">
                <h2 className="display-3 fs-5-integral ls-4 mb-4">{content.title}</h2>

                <div className="fw-light fs-2-integral">{ ReactHtmlParser(content.text.html) }</div>

                <Link className="w-50 btn btn-primary fw-bold text-white" to={content.link}>{content.button}</Link>
            </div>
        </section>
    );
};

export default RedirectPageSectionComponent;
