const PageSectionOptionsEnum = {
    IMAGE: "image",
    LEFT: "left",
    RIGHT: "right",
    TEXT: "text"
};

// Prevent any modification
Object.freeze(PageSectionOptionsEnum);

export default PageSectionOptionsEnum;
