import React, { useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import AddressBlockComponent from "../../blocks/AddressBlockComponent";
import SocialNetworksBlockComponent from "../../blocks/SocialNetworksBlockComponent";

import ChevronInputIcon from "../../../assets/medias/svg/icons/icon-chevron-input.svg";

const ContactFormPageSectionComponent = ({ content }) => {
    const {t} = useTranslation();

    const [brand, setBrand] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const updateBrand = (event) => { setBrand(event.target.value) };
    const updateEmail = (event) => { setEmail(event.target.value) };
    const updateMessage = (event) => { setMessage(event.target.value) };

    const handleSubmit = (event) => {
        /* HERE poke the mail server */
        event.preventDefault();
    };
    
    return (
        <section className="text-center contact-form-wrapper py-section section-light-theme">
            <div className="container">
                <div className="d-flex fw-light justify-content-center fs-2-integral mb-5 text-mw">{ ReactHtmlParser(content.text.html) }</div>

                <div className="row content-mx">
                    <form className="col-12 col-md-6" onSubmit={handleSubmit}>
                        <div className="d-flex flex-row-reverse input-group mb-4 align-items-center">
                            <label htmlFor="brand" className="form-label visually-hidden">{t("app.contact.company")}</label>
                            <input id="brand" name="brand" type="text" className="form-control" required
                                   placeholder={t("app.contact.yourCompany")} value={brand} onChange={updateBrand} />
                            <div className="icon-prefix"><ChevronInputIcon /></div>
                        </div>

                        <div className="d-flex flex-row-reverse input-group mb-4 align-items-center">
                            <label htmlFor="email" className="form-label visually-hidden">{t("app.contact.email")}</label>
                            <input id="email" name="email" type="email" className="form-control" required
                                   placeholder={t("app.contact.yourEmail")} value={email} onChange={updateEmail} />
                            <div className="icon-prefix"><ChevronInputIcon /></div>
                        </div>

                        <div className="d-flex flex-row-reverse input-group mb-4 align-items-start">
                            <label htmlFor="message" className="form-label visually-hidden">{t("app.contact.message")}</label>
                            <textarea id="message" name="message" rows="8" className="form-control" required
                                      placeholder={t("app.contact.yourMessage")} value={message} onChange={updateMessage} />
                            <div className="icon-prefix"><ChevronInputIcon /></div>
                        </div>

                        <button type="submit" name="submit" className="w-50 btn btn-primary fw-bolder mb-5 mb-md-0">{t("app.contact.send")}</button>
                    </form>

                    <div className="d-flex flex-column col-12 col-md-6 align-items-center">
                        <h3 className="fw-light fs-4-integral ls-3 mb-3">{content.subtitle}</h3>

                        <AddressBlockComponent containerClassName="mb-4" />

                        <span className="w-75 btn btn-outline-primary mb-4">
                            {t("app.global.contactUppercase")}
                            <br className="d-xxl-none" />
                            {t("app.brand.contactEmail")}
                        </span>
                        <span className="w-75 btn btn-outline-primary mb-4">
                            {t("app.global.supportUppercase")}
                            <br className="d-xxl-none" />
                            {t("app.brand.supportEmail")}
                        </span>

                        <SocialNetworksBlockComponent svgThemeClass="svg-light" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactFormPageSectionComponent;
