import React, { useEffect, useState } from "react";

import ArticleOverviewPageSectionComponent from "../../sections/pages/ArticleOverviewPageSectionComponent";

import useArticlesQuery from "../../../data-loader/ArticlesDataLoader";
import SpinnerBlockComponent from "../../blocks/SpinnerBlockComponent";

const ArticlesListPageSectionComponent = ({ articlesFiltered, theme }) => {
    const [isLoading, setLoading] = useState(true);
    const [articlesList, setArticlesList] = useState();

    const { allMarkdownRemark } = useArticlesQuery();

    useEffect(() => {
        if(articlesFiltered) {
            // We are at "/tags" route, so filtered list
            setArticlesList(articlesFiltered);
        } else {
            // We are at "/blog" route, so all articles
            setArticlesList(allMarkdownRemark);
        }
        setLoading(false);
    }, [allMarkdownRemark, articlesFiltered]);

    const ArticlesList = () => {
        let articlesArray = [];

        articlesList.nodes.forEach((article, index) =>  {
            articlesArray.push(
                <ArticleOverviewPageSectionComponent key={index} article={article.frontmatter} theme={theme} />
            );
        });

        return(
            <>{articlesArray}</>
        );
    };

    return(
        <>
            {isLoading ? 
            <div className="d-flex justify-content-center py-section">
                <SpinnerBlockComponent />
            </div>
            :
            <div className="pt-5">
                <ArticlesList />
            </div>}

        </>
    );
};

export default ArticlesListPageSectionComponent;
