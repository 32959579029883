import React, { useEffect, useRef } from "react";
import ReactHtmlParser from 'react-html-parser';

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

import PageSectionOptionsEnum from "../../../enums/PageSectionOptionsEnum";

const HalvesPageSectionComponent = ({ content, side = PageSectionOptionsEnum.RIGHT }) => {
    const rowRef = useRef();

    useEffect(() => {
        if(side === PageSectionOptionsEnum.LEFT) {
            rowRef.current.classList.add("flex-row-reverse");
        }
    }, [side]);

    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container px-5 px-md-3 px-lg-5">
                <div ref={rowRef} className="row d-flex align-items-center gx-4 gx-md-5">
                    <div className="d-flex col-12 col-md-6 justify-content-center mb-4 mb-md-0">
                        <ImageBlockComponent src={content.image} alt={content.alt} />
                    </div>

                    <div className="col-12 col-md-6">
                        <em>{content.subtitle}</em>
                        <h2 className="display-3 fs-5-integral ls-4 mb-4">{content.title}</h2>

                        <div className="fw-light fs-2-integral">{ ReactHtmlParser(content.text.html) }</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HalvesPageSectionComponent;
