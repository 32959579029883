import React, { useEffect, useRef } from "react";
import ReactHtmlParser from 'react-html-parser';
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

import PageSectionOptionsEnum from "../../../enums/PageSectionOptionsEnum";

const ServicePageSectionComponent = ({ content, side = PageSectionOptionsEnum.RIGHT }) => {
    const {t} = useTranslation();
    const rowRef = useRef();

    useEffect(() => {
        if(side === PageSectionOptionsEnum.LEFT) {
            rowRef.current.classList.add("flex-row-reverse");
        }
    }, [side]);
    
    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container px-5 px-md-3 px-lg-5 mw-960px">
                <div ref={rowRef} className="row d-flex align-items-center gx-4 gx-md-5">
                    <div className="d-flex col-12 col-md-5 justify-content-center mb-4 mb-md-0">
                        <ImageBlockComponent src={content.image} alt={content.alt} />
                    </div>

                    <div className="col-12 col-md-7">
                        <h2 className="fs-2 ls-4 mb-2">{content.title}</h2>
                        <h3 className="fs-5 ls-3 mb-3">{content.subtitle}</h3>

                        <div className="fw-light fs-6">{ ReactHtmlParser(content.text.html) }</div>

                        <Link className="fw-bold btn btn-primary text-white" to={content.link}>{t("app.button.learnMore")}</Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicePageSectionComponent;
