import React from "react";

import FooterComponent from "../FooterComponent";
import HeaderWrapperComponent from "../headers/HeaderWrapperComponent";
import SEOComponent from "../SEOComponent";

const PageLayoutComponent = ({ children, page }) => {
    return (
        <>
            <SEOComponent pageNode={page} pageSEO />

            <HeaderWrapperComponent type="small" title={page.title} subtitle={page.description} cover={page.cover} />

            <main>{ children }</main>

            <FooterComponent/>
        </>
    )
};

export default PageLayoutComponent;
