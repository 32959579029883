const PageSectionTypesEnum = {
    ARTICLES: "articles",
    CHECKS: "checks",
    CONTACT: "contact",
    GRID: "grid",
    HALVES: "halves",
    MAIN: "main",
    REALISATION: "realisation",
    REDIRECT: "redirect",
    SERVICE: "service",
    THIRDS: "thirds"
};

// Prevent any modification
Object.freeze(PageSectionTypesEnum);

export default PageSectionTypesEnum;
