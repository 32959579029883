import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useImagesQuery from "../../data-loader/ImagesDataLoader";

const ImageBlockComponent = ({alt, containerClassName, src}) => {
    const { allFile } = useImagesQuery();

    const rawImage = allFile.nodes.find( (node) => node.base === src );
    const image = getImage(rawImage.childImageSharp);

    return (
        <GatsbyImage className={containerClassName} imgClassName="img-fluid" image={image} alt={alt} />
    );
};

export default ImageBlockComponent;
