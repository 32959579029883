import React from "react";
import ReactHtmlParser from 'react-html-parser';

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

const GridPageSectionComponent = ({ content }) => {
    let colsClasses = "col-12 col-md-6 col-lg-4"; // By default, itemsByNow = 3
    let paddingClass = "p-4";

    if(content.itemsByRow === 2) {
        colsClasses = "col-12 col-md-6";
        paddingClass = "p-5";
    }
    if(content.itemsByRow === 4) {
        colsClasses = "col-12 col-md-6 col-lg-4 col-xl-3";
        paddingClass = "p-3";
    }
    
    const GridContentParser = () => {
        let gridContentArray = [];

        content.gridContent.forEach((card, index) =>  {
            gridContentArray.push(
                <div key={index} className={colsClasses + " grid-card-wrapper " + paddingClass}>
                    <ImageBlockComponent containerClassName="mb-3 grid-card" src={card.image} alt={card.alt} />

                    <h3 className="fw-light fs-4-integral ls-3 mb-3">{card.subtitle}</h3>
                    <div className="fw-light fs-2-integral grid-card-mb0">{ ReactHtmlParser(card.text.html) }</div>
                </div>
            );
        });

        return(
            <>{gridContentArray}</>
        );
    };

    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container d-flex flex-column align-items-center px-5 px-md-3 px-lg-5 text-center">
                <h2 className="display-3 fs-5-integral ls-4">{content.title}</h2>

                <div className="fw-light fs-2-integral mb-4">{ ReactHtmlParser(content.text.html) }</div>

                <div className="row d-flex justify-content-center"><GridContentParser /></div>
            </div>
        </section>
    );
};

export default GridPageSectionComponent;
