import React from "react";
import ReactHtmlParser from 'react-html-parser';

import ImageBlockComponent from "../../blocks/ImageBlockComponent";

const MainPageSectionComponent = ({ content }) => {
    return (
        <section className={"py-section section-" + content.theme + "-theme"}>
            <div className="container d-flex flex-column align-items-center px-5 px-md-3 px-lg-5 text-center">
                <div className="mb-5">
                    <ImageBlockComponent src={content.image} alt={content.alt} />
                </div>

                <h2 className="display-3 fs-5-integral ls-4 mb-4">{content.title}</h2>

                <div className="fw-light fs-2-integral">{ ReactHtmlParser(content.text.html) }</div>
            </div>
        </section>
    );
};

export default MainPageSectionComponent;
