import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ArticlesListPageSectionComponent from "../sections/pages/ArticlesListPageSectionComponent";
import ChecksPageSectionComponent from "../sections/pages/ChecksPageSectionComponent";
import ContactFormPageSectionComponent from "../sections/pages/ContactFormPageSectionComponent";
import GridPageSectionComponent from "../sections/pages/GridPageSectionComponent";
import HalvesPageSectionComponent from "../sections/pages/HalvesPageSectionComponent";
import MainPageSectionComponent from "../sections/pages/MainPageSectionComponent";
import RealisationPageSectionComponent from "../sections/pages/RealisationPageSectionComponent";
import RedirectPageSectionComponent from "../sections/pages/RedirectPageSectionComponent";
import ServicePageSectionComponent from "../sections/pages/ServicePageSectionComponent";
import ThirdsPageSectionComponent from "../sections/pages/ThirdsPageSectionComponent";

import BannerBlockComponent from "../blocks/BannerBlockComponent";
import PageLayoutComponent from "../layouts/PageLayoutComponent";

import PageSectionTypesEnum from "../../enums/PageSectionTypesEnum";

const PageTemplateComponent = ({ pageContext }) => {
    const {t} = useTranslation();
    const page = pageContext.page.frontmatter;

    const PageContentParser = () => {
        let pageSectionComponentArray = [];

        page.content?.forEach((section, index) =>  {
            switch(section.type) {
                case PageSectionTypesEnum.MAIN:
                    pageSectionComponentArray.push(<MainPageSectionComponent key={index} content={section} />);
                    break;
                case PageSectionTypesEnum.HALVES:
                    pageSectionComponentArray.push(<HalvesPageSectionComponent key={index} content={section} side={section.side} />);
                    break;
                case PageSectionTypesEnum.THIRDS:
                    pageSectionComponentArray.push(<ThirdsPageSectionComponent key={index} content={section} side={section.side} />);
                    break;
                case PageSectionTypesEnum.SERVICE:
                    pageSectionComponentArray.push(<ServicePageSectionComponent key={index} content={section} side={section.side} />);
                    break;
                case PageSectionTypesEnum.REALISATION:
                    pageSectionComponentArray.push(<RealisationPageSectionComponent key={index} content={section} side={section.side} />);
                    break;
                case PageSectionTypesEnum.CHECKS:
                    pageSectionComponentArray.push(<ChecksPageSectionComponent key={index} content={section} />);
                    break;
                case PageSectionTypesEnum.GRID:
                    pageSectionComponentArray.push(<GridPageSectionComponent key={index} content={section} />);
                    break;
                case PageSectionTypesEnum.REDIRECT:
                    pageSectionComponentArray.push(<RedirectPageSectionComponent key={index} content={section} />);
                    break;
                case PageSectionTypesEnum.CONTACT:
                    pageSectionComponentArray.push(<ContactFormPageSectionComponent key={index} content={section} />);
                    break;
                case PageSectionTypesEnum.ARTICLES:
                    pageSectionComponentArray.push(<ArticlesListPageSectionComponent key={index} theme={section.theme} />);
                    break;
                default:
                    break;
            }
        });

        // Generate an integer between 0 and 999, with a positive offset of 1000
        // This way, we should be sure we'll never have a conflict with other sections
        // Only if we have more than 1000 sections in a single page D:
        const randomIndex = Math.floor(Math.random() * 1000) + 1000;
        if(pageContext.page.html) {
            pageSectionComponentArray.push(
                <div key={randomIndex} className="container d-flex justify-content-center py-5 article-template-wrapper">
                    <div className="fw-light fs-2-integral article-content mw-960px">
                            { ReactHtmlParser(pageContext.page.html) }
                    </div>
                </div>
            );
        }

        return(
            <>{pageSectionComponentArray}</>
        );
    };

    return (
        <PageLayoutComponent page={page}>
            <PageContentParser />

            <BannerBlockComponent catchPhrase={t("app.catchPhrase.haveYouProject")} />
        </PageLayoutComponent>
    );
};

export default PageTemplateComponent;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
